export enum Links {
    /**
     * @description GET
     */
    GET_COUNTRIES = '/health/countries',
    GET_COUNTRIES_CODE = '/car/country-codes',
    /**
     * @description POST
     */
    POST_PERSONAL_DETAILS = '/expat/save/customer',
    GET_CUSTOMER = '/expat/customer/details',
    
    GET_QUOTES = '/expat/get-quotes',
    PROCEED_BUY = '/expat/premium/buy-now',

    GET_LEAD_DETAILS = '/expat/customer/LEADID/lead',
    GET_CUSTOMER_DETAILS = '/expat/customer/CUSTOMER_ID/details'

};
