import Quotes from '@/core/services/Quotes';
import { Addons, ExpatPlans } from '@/core/types/Quotes';


export type State = {
  expatPlans: Array<ExpatPlans> | null;
  loading: Boolean,
  isDisable: Boolean | null,
  isLoading: Boolean,
  hasData: Boolean
}



export const state: State = {
  expatPlans: [{
    premium_id:0,
    plan_name:'',
    network_type:'',
    network_type_condition:'',
    provider_name:'',
    provider_image:'',
    premium:0,
    benefits:[],
    selected_addons:[],
    addons:[
      {
        addon_id:0,
        addon:'',
        value:'',
        amount:0,
        selected:false,
        prev_price:0
    }
    ] as Array<Addons>
  }] as Array<ExpatPlans>,
  loading: false,
  isDisable: false,
  isLoading: false,
  hasData: true
};