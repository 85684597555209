import { App } from "vue";
import { FormInstance } from "element-plus";
import pd from "@/core/services/PersonalDetailsService";
import { store } from "@/store";

export const personalDetails = (app: App<Element>) => {
    const dates = app.config.globalProperties.dates;
    app.config.globalProperties.personalDetails = {
        async click(formEl: FormInstance) {
            if (!formEl) return;
            formEl.validate(async (valid, fields: any) => {
                if (valid) {
                    pd.setLoading(true);
                    const customer = store.state.personals.customer
                    await pd.post(customer);
                    pd.setLoading(false);
                } else {
                    console.log(Object.keys(fields)[0]);
                    this.scrollToError(Object.keys(fields)[0]);
                }
            })
        },


        scrollToError(e:string) {
            if(e == 'expat_name') {
                setTimeout(() => {
                    window.scroll(0, 200)
                }, 500);
            } 
            else if(e == 'leads.expat_dob') {
                setTimeout(() => {
                    window.scroll(0, 450)
                }, 500);
            } 
            else if(e == 'expat_gender') {
                setTimeout(() => {
                    window.scroll(0, 550)
                }, 500);
            } 
            else if(e == 'customer_name') {
                setTimeout(() => {
                    window.scroll(0, 550)
                }, 500);
            } 
            else if(e == 'customer_phone') {
                setTimeout(() => {
                    window.scroll(0, 650)
                }, 500);
            } 
            else if(e == 'customer_email') {
                setTimeout(() => {
                    window.scroll(0, 700)
                }, 500);
            } 
            else {
                setTimeout(() => {
                    window.scroll(0, 0)
                }, 500);
            }
        }
    }
}