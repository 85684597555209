<template>
  <div class="form-block">
    <div class="label-block">
      <div class="label-block_label">
        <label>{{ label }}<span v-if="isRequired"> *</span> </label>
      </div>
      <div class="label-block_question position-relative" v-if="isTooltip">
        <el-tooltip class="box-item" effect="light">
          <template #content>
            <p class="tooltip-content">{{ tooltipMessage }}</p>
          </template>
          <a
            class="popper"
            data-bs-trigger="hover"
            placement="right"
            data-bs-toggle="popover"
          >
            <div class="icon">
              <span class="icon-question-icon"></span>
            </div>
          </a>
        </el-tooltip>
      </div>
    </div>

    <div class="input-block">
      <div class="row">
        <div class="col-lg-10">
          <div class="row-input">
            <div class="row gx-20">
              <div class="col-sm-12">
                <el-form-item :prop="dayProp">
                  <el-input
                    type="text"
                    placeholder="DD/MM/YYYY"
                    v-model="firstDate"
                    @input="updateValue"
                    @change="changeValue"
                    maxlength="10"
                    :disabled="disabled"
                    ref="inputRef"
                    size="large"
                    @keydown.enter="closeCalendar"
                    >
                    <!-- @click="openCalendar" -->
                        <template #suffix>
                            
                            <el-date-picker
                                style="visibility: hidden; width: 1px;"
                                v-model="firstDate"
                                type="date"
                                placeholder="Pick a Date"
                                :default-value="max"
                                format="YYYY-MM-DD"
                                value-format="DD/MM/YYYY"
                                :disabled-date="disabledDate"
                                ref="calRef"
                                @change="changeValue"
                            />
                            
                            <el-icon class="el-input__icon pointer" @click="openCalendar"><calendar /></el-icon>
                        </template>
                        
                  </el-input>
                    
                </el-form-item>
              </div>

              <el-form-item :prop="formProp">
                <el-input type="hidden" :model-value="modelValue" />
              </el-form-item>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
/* eslint-disable */
import { computed, defineComponent, toRef, watchEffect, ref, watch, nextTick } from "vue";
import moment from "moment";
import { Calendar } from '@element-plus/icons-vue'

export default defineComponent({
  props: {
    label: {
      type: String,
      required: true,
      default: "Date of Birth",
    },
    formProp: {
      type: String,
      required: true,
      default: "dob",
    },
    modelValue: {
      type: String,
      required: true,
      default: "",
    },
    
    isTooltip: {
      type: Boolean,
      required: false,
      default: false,
    },
    isRequired: {
      type: Boolean,
      required: false,
      default: false,
    },
    tooltipMessage: {
      type: String,
      required: false,
      default: "",
    },
    ageValue: {
      type: Number,
      required: false,
      default: 0
    },
    min: {
        type: String,
        required: false,
        default: "",
    },
    max: {
        type: String,
        required: false,
        default: "",
    },
  },

  setup(_, context) {
    /* eslint-disable */
    const language = computed(() => {
      return window.localStorage.getItem("language");
    });

    const firstDate = ref("");

    watch(firstDate, (value) => {
      const result = value
        .replace(/^(\d\d)(\d)$/g, "$1/$2")
        .replace(/^(\d\d\/\d\d)(\d+)$/g, "$1/$2")
        .replace(/[^\d/]/g, "");
      nextTick(() => (firstDate.value = result));
      
    });

    const myAge = toRef(_, "ageValue");;

    
    watchEffect(() => {
      if(_.modelValue) {
        firstDate.value = moment(_.modelValue, "YYYY-MM-DD").format(
          "DD/MM/YYYY"
        );
      } else {
        firstDate.value = ''
      }
    })

    const updateValue = () => {
      if (firstDate.value.length == 10) {
        let myDate = moment(firstDate.value, "DD/MM/YYYY").format("YYYY-MM-DD");
        context.emit("update:modelValue", myDate);
        closeCalendar()
      }
    };

    
    const setDateFormat = () => {
      context.emit(
        "update:modelValue",
        moment(firstDate.value).format("YYYY-MM-DD")
      );
      let setDate = moment(firstDate.value, "YYYY-MM-DD").format("DD/MM/YYYY");
      firstDate.value = setDate;
      context.emit('blur-out', firstDate.value)
    };

    const inputRef = ref()
    const focus = () => {
      // setTimeout(() => {
      //   inputRef.value.focus()
      // }, 250);
    }

    
    const setDateFormatInput = () => {
      let dateMinEight = moment(firstDate.value, 'YYYYMMDD').format('YYYY-MM-DD')

      if(firstDate.value.length === 8 && dateMinEight != "Invalid date") {
        let setDateEight = moment(dateMinEight, "YYYY-MM-DD").format("DD/MM/YYYY");
        firstDate.value = setDateEight
        context.emit("update:modelValue", dateMinEight);
      }
      context.emit('blur-out', firstDate.value)
    };

    
    const changeValue = () => {
      context.emit("change")
      let myDate = moment(firstDate.value, "DD/MM/YYYY").format("YYYY-MM-DD");
      const currentDate =
        _.max != ""
          ? _.max
          : new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
              .toISOString()
              .substr(0, 10);
      const minDate = moment(_.min, "YYYY-MM-DD").format("DD/MM/YYYY");
      const setDate = moment(currentDate, "YYYY-MM-DD").format("DD/MM/YYYY");
      if (firstDate.value.length != 10) {
        firstDate.value = "";
        context.emit("update:modelValue", "");
      }

      if (_.modelValue == "Invalid Date") {
        firstDate.value = "";
        context.emit("update:modelValue", "");
      }

      if (myDate == "NaN-NaN-NaN") {
        firstDate.value = "";
        context.emit("update:modelValue", "");
      }

      if (firstDate.value.length == 10 && myDate != "Invalid date") {
        if (
          _.max != "" &&
          myDate > moment(currentDate).format("YYYY-MM-DD")
        ) {
          firstDate.value = "";
          context.emit("update:modelValue", "");
        } else if (
          _.min != "" &&
          myDate < moment(_.min).format("YYYY-MM-DD")
        ) {
          firstDate.value = "";
          context.emit("update:modelValue", "");
        } else {
          context.emit("update:modelValue", myDate);
        }
      } else {
        firstDate.value = "";
        context.emit("update:modelValue", "");
      }

      
      context.emit("vcBlur")
      context.emit("blur-out")
    };


    const calRef = ref()
    const openCalendar = () => {
        calRef.value.handleOpen()
        focus()
    }

    const closeCalendar = () => {
        calRef.value.handleClose()
    }

    const disabledDate = (time: Date) => {
        if(_.min || _.max) {
            const min = moment(_.min, 'YYYY-MM-DD').valueOf()
            const max = moment(_.max, 'YYYY-MM-DD').valueOf()
            if(_.min && !_.max) return time.getTime() < min
            if(!_.min && _.max) return time.getTime() > max
            if(_.min && _.max) return time.getTime() < min || time.getTime() > max
        }
    }

    return {
        disabledDate,
        Calendar,
      firstDate,
      calRef,
      inputRef,
      focus,
      closeCalendar,
      openCalendar,
      updateValue,
      setDateFormat,
      setDateFormatInput,
      changeValue,
      myAge,
      language,
    };
  },
});
</script>

<style>
    .pointer{
        cursor: pointer;
    }
</style>
