import { store } from "@/store";
import { App } from "vue";
import { PersonalActionTypes } from "@/core/enum/Personals/Actions";
import { PersonalMutationTypes } from "@/core/enum/Personals/Mutations";
import { CustomerAndLeadId } from '@/core/types';

class PersonalDetailsService{

    public static vueInstance: App;

    public static init(app: App<Element>) {
        PersonalDetailsService.vueInstance = app;
    }

    public static async post(payload) {
        return await store
        .dispatch(PersonalActionTypes.POST_PERSONAL_DETAILS, payload);
    }

    public static async getCustomerDetails(payload) {
        return await store
        .dispatch(PersonalMutationTypes.GET_CUSTOMER, payload);
    }

    public static async getLeadDetails(payload) {
        return await store
        .dispatch(PersonalMutationTypes.GET_LEAD_DETAILS, payload);
    }

    public static setResult(payload: CustomerAndLeadId) {
        store.commit(PersonalMutationTypes.SET_CUSTOMER_RESULT, payload);
    }


    public static setLoading(payload: Boolean) {
        store.commit(PersonalMutationTypes.SET_CUSTOMER_LOADING, payload);
    }
}

export default PersonalDetailsService;