import { GetterTree } from 'vuex';

// eslint-disable-next-line import/no-cycle
import { RootState } from '@/store';

import { ExpatHealthLeadDetails } from '@/core/types';

import { State } from './state';

export type Getters = {
  getCustomer(state: State): ExpatHealthLeadDetails;
}

export const getters: GetterTree<State, RootState> & Getters = {
  getCustomer: (state) => state.customer,
};
