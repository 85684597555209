import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createBlock as _createBlock, withModifiers as _withModifiers, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "result-listing__item lowest-price position-relative expat-insurance-item" }
const _hoisted_2 = { class: "result-listing__item__block overflow-hidden" }
const _hoisted_3 = { class: "result-listing__item__block-top" }
const _hoisted_4 = { class: "row g-0" }
const _hoisted_5 = { class: "col-lg-10 col-md-12 left-col" }
const _hoisted_6 = { class: "result-listing__item__block_left h-100" }
const _hoisted_7 = { class: "result-listing__item__block_top" }
const _hoisted_8 = { class: "result-listing__item__block_top_row d-flex flex-wrap align-items-center" }
const _hoisted_9 = { class: "partner-logo" }
const _hoisted_10 = ["src"]
const _hoisted_11 = { class: "partner-content" }
const _hoisted_12 = { class: "partner-content_title" }
const _hoisted_13 = { class: "mb-0" }
const _hoisted_14 = { class: "partner-content_bottom d-flex" }
const _hoisted_15 = { class: "partner-content_bottom_item" }
const _hoisted_16 = { class: "line-height-15" }
const _hoisted_17 = {
  key: 0,
  class: "network-type-condition"
}
const _hoisted_18 = { class: "result-listing__item__block_medium" }
const _hoisted_19 = { class: "row gx-5" }
const _hoisted_20 = { class: "col-md-8 col-sm-12" }
const _hoisted_21 = { class: "listing-ul" }
const _hoisted_22 = { class: "row" }
const _hoisted_23 = { class: "mw-100" }
const _hoisted_24 = { class: "col-lg-2 col-md-12 right-col" }
const _hoisted_25 = { class: "right-buy-now text-center" }
const _hoisted_26 = { class: "right-buy-now-top" }
const _hoisted_27 = { class: "premium-title" }
const _hoisted_28 = { class: "price-title" }
const _hoisted_29 = {
  class: "mb-0",
  style: {"direction":"ltr"}
}
const _hoisted_30 = { class: "buy-now-button" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_quote_addon = _resolveComponent("quote-addon")!
  const _component_el_button = _resolveComponent("el-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("img", {
                      src: `${_ctx.quote.provider_image}`,
                      class: "img-fluid"
                    }, null, 8, _hoisted_10)
                  ]),
                  _createElementVNode("div", _hoisted_11, [
                    _createElementVNode("div", _hoisted_12, [
                      _createElementVNode("h2", _hoisted_13, _toDisplayString(_ctx.quote.plan_name), 1)
                    ]),
                    _createElementVNode("div", _hoisted_14, [
                      _createElementVNode("div", _hoisted_15, [
                        _createElementVNode("p", _hoisted_16, [
                          _createTextVNode(_toDisplayString(_ctx.quote.network_type) + " ", 1),
                          (_ctx.quote.network_type_condition)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_17, _toDisplayString(_ctx.quote.network_type_condition), 1))
                            : _createCommentVNode("", true)
                        ])
                      ])
                    ])
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_18, [
                _createElementVNode("div", _hoisted_19, [
                  _createElementVNode("div", _hoisted_20, [
                    _createElementVNode("div", _hoisted_21, [
                      _createElementVNode("ul", _hoisted_22, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.quote.benefits, (benefit, bnIndex) => {
                          return (_openBlock(), _createElementBlock("li", {
                            key: bnIndex,
                            class: _normalizeClass(benefit.icon + ` col-md-6 col-sm-12`)
                          }, [
                            _createTextVNode(_toDisplayString(benefit.benefit) + " ", 1),
                            _createElementVNode("span", _hoisted_23, _toDisplayString(benefit.value), 1)
                          ], 2))
                        }), 128))
                      ])
                    ])
                  ]),
                  (_ctx.quote.addons && _ctx.quote.addons.length > 0)
                    ? (_openBlock(), _createBlock(_component_quote_addon, {
                        key: 0,
                        addons: _ctx.quote.addons,
                        onToggleAddon: _ctx.handleToggleAddon
                      }, null, 8, ["addons", "onToggleAddon"]))
                    : _createCommentVNode("", true)
                ])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_24, [
            _createElementVNode("div", _hoisted_25, [
              _createElementVNode("div", _hoisted_26, [
                _createElementVNode("div", _hoisted_27, [
                  _createElementVNode("p", null, _toDisplayString(_ctx.$t("total_premium")), 1)
                ]),
                _createElementVNode("div", _hoisted_28, [
                  _createElementVNode("h4", _hoisted_29, " $ " + _toDisplayString(_ctx.$filters.currency(_ctx.quote.premium) || 0), 1)
                ]),
                _createElementVNode("div", _hoisted_30, [
                  _createVNode(_component_el_button, {
                    disabled: _ctx.quote.premium <= 0,
                    onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.BuyNow(_ctx.quote)), ["prevent"])),
                    class: "btn buy-now"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("buy_now")), 1)
                    ]),
                    _: 1
                  }, 8, ["disabled"])
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  ]))
}