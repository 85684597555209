import { store } from "@/store";
import moment from "moment";

const language = window.localStorage.getItem('language') || 'en' 

class Validations {
    public static country_code = '+961'
    public static customer() {
        return store.state.personals.customer
    }
    
    public static email(rule: any, value: any, callback: any) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(!value) {
            callback(new Error( language === 'ar' ? 'الرجاء تسجيل بريدك الإلكتروني' : 'Please enter your email'))
        } else if(!re.test(String(value).toLowerCase())) {
            callback(new Error( language === 'ar' ? 'يجب أن يكون عنوان البريد الإلكتروني صالحًا' : 'Email address must be valid'))
        } else {
            callback();
        }
    }

    public static names(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error( language === 'ar' ? 'الرجاء إدخال اسمك بالكامل' : 'Please enter your full name'))
        } else {
            callback();
        }
    }

    public static expatName(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error( language === 'ar' ? 'الرجاء إدخال اسمك بالكامل' : 'Please enter expat full name'))
        } else {
            callback();
        }
    }

    public static phone_number(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error( language === 'ar' ? 'الرجاء إدخال رقم الهاتف' : 'Please enter phone number'))
        } 
        
        if (Validations.country_code === '+961' && (value < 999999 || value > 99999999)) {
            callback(new Error( language === 'ar' ? 'يرجى إدخال 7 أرقام على الأقل و8 أرقام كحد أقصى.' : 'Please enter at least 7 digits and max 8 digits.'))
        } else if (Validations.country_code !== '+961' && (value < 9999999 || value > 9999999999)) {
            callback(new Error( language === 'ar' ? 'يرجى إدخال 8 أرقام على الأقل و 10 أرقام كحد أقصى.' : 'Please enter at least 8 digits and max 10 digits.'))
        } else {
            callback();
        }
    }

    public static gender(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error( language === 'ar' ? 'Please select gender' : 'Please select gender'))
        } 
        else {
            callback();
        }
    }

    public static dob(rule: any, value: any, callback: any) {
        if(value === 'Invalid date' || !value) {
            callback(new Error(`Please enter date of birth`));
        } else if(moment().diff(value, 'days') < 1) {
            callback(new Error('You still don\'t exist'))
        } else {
            callback();
        }
    }


};

export default Validations;
