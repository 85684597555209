<template>
    <h-header-mobile />
    <section class="vechile-detail-section" v-if="expatPlans && expatPlans.length > 0">
        <div class="container" v-if="expatPlans[0].plan_name">
            <div class="vechile-detail-container">
                <div class="vechile-detail_row quote-title-block">
                    <div class="row justify-content-between">
                        <div class="form-left-column w-100 pt-10">
                            <h-title :title="$t('compare_quotes')" :isShow="false" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="vechile-list-container main-container">
                <div class="vechile-list_row">
                    <div class="result-listing">
                        <div class="result-listing-full-block">
                            <quote-card v-for="(quote, index) in expatPlans" :quote="quote" :index="index" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container" style="min-height: 50vh" v-else></div>
    </section>

    <no-quote-found v-if="expatPlans && expatPlans.length <= 0" :homeUrl="websiteHomePage" />
</template>

<script lang="ts">
    import { store } from "@/store";
    import { currency } from "@/core/functions/currency";
    import Quotes from "@/core/services/Quotes";
    import axios from "axios";
    import { Links } from "@/core/enum/Links";
    import { ElLoading } from "element-plus";
    import { defineComponent, onMounted, computed, ref, toRef } from "vue";

    import QuoteCard from "@/components/quotes/components/QuoteCard.vue";
    import NoQuoteFound from "@/components/quotes/components/NoQuoteFound.vue";

    export default defineComponent({
        components: {
            QuoteCard,
            NoQuoteFound,
        },
        setup(_) {
            let expatPlans = computed(() => {
                return store.state.quotes.expatPlans;
            });

            const language = computed(() => {
                return window.localStorage.getItem("language");
            });

            const websiteHomePage = computed(() => {
                const lang = language.value || "en";
                const baseUrl = process.env.VUE_APP_WEBSITE_URL;
                return `${baseUrl}/${lang}`;
            });

            onMounted(async () => {
                const lead_id: any = window.localStorage.getItem("lead_id");

                const loading = ElLoading.service({
                    lock: true,
                    text: "Loading",
                    background: "rgba(0, 0, 0, 0.9)",
                });
                const payload = {
                    language: window.localStorage.getItem("language"),
                    lead_id: window.localStorage.getItem("lead_id"),
                };
                await Quotes.getQuotes(payload);
                loading.close();
            });

            return {
                expatPlans,
                language,
                websiteHomePage,
            };
        },
    });
</script>