import { MutationTree } from 'vuex';

import { ExpatHealthLeadDetails, CustomerAndLeadId } from '@/core/types';

import { State } from './state';
import { PersonalMutationTypes } from '@/core/enum/Personals/Mutations';

export type Mutations<S = State> = {
  [PersonalMutationTypes.GET_CUSTOMER](state: S, payload: ExpatHealthLeadDetails): void;
  [PersonalMutationTypes.SET_CUSTOMER](state: S, payload: ExpatHealthLeadDetails): void;
  [PersonalMutationTypes.SET_CUSTOMER_RESULT](state: S, payload: CustomerAndLeadId): void;
  [PersonalMutationTypes.SET_CUSTOMER_LOADING](state: S, payload: Boolean): void;
}

export const mutations: MutationTree<State> & Mutations = {
  [PersonalMutationTypes.SET_CUSTOMER](state: State, customer: ExpatHealthLeadDetails) {
    state.customer = customer;
  },
  [PersonalMutationTypes.GET_CUSTOMER](state: State, customer: ExpatHealthLeadDetails) {
    state.customer = customer;
  },
  [PersonalMutationTypes.GET_LEAD_DETAILS](state: State, customer: ExpatHealthLeadDetails) {
    state.customer = customer;
  },
  [PersonalMutationTypes.SET_CUSTOMER_RESULT](state: State, result: CustomerAndLeadId) {
    state.result = result;
  },
  [PersonalMutationTypes.SET_CUSTOMER_LOADING](state: State, loading: Boolean) {
    state.loading = loading;
  },
};
