import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "vechile-detail-section" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "vechile-detail-container" }
const _hoisted_4 = { class: "vechile-detail_row" }
const _hoisted_5 = { class: "row gx-5 justify-content-center" }
const _hoisted_6 = { class: "col-lg-9" }
const _hoisted_7 = { class: "vechile-detail__block" }
const _hoisted_8 = { class: "row justify-content-center" }
const _hoisted_9 = { class: "col-lg-11 col-md-7 form-left-column" }
const _hoisted_10 = { class: "vechile-details-form" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_h_header_mobile = _resolveComponent("h-header-mobile")!
  const _component_h_title = _resolveComponent("h-title")!
  const _component_h_personal_details = _resolveComponent("h-personal-details")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createVNode(_component_h_header_mobile),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, [
                    _createVNode(_component_h_title, {
                      title: _ctx.$t('personal_details'),
                      isShow: true
                    }, null, 8, ["title"]),
                    _createElementVNode("div", _hoisted_10, [
                      _createVNode(_component_h_personal_details)
                    ])
                  ])
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  ]))
}