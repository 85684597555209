<template>
    <div class="page-title-block d-flex align-items-center flex-wrap justify-content-between page-title-container" v-if="isShow">
        <div class="page-title mb-0 ">
            <h2 class="mb-0"> {{ title }} </h2>
        </div>
        <div class="page-title-desc">
            <h5 class="mb-0">{{ $t('will_take') }}</h5>
        </div>
    </div>


    <div class="page-title-block result-listing-title inner expat-compare-title-header" v-else>
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="page-title mb-0">
                    <h2 class="mb-0">{{ title }}</h2>
                </div>
            </div>
        </div>
    </div>

</template>
<script lang="ts">

export default {
    name: 'TitleBar',

    props: {
        title: {
            required: true,
            type: String
        },
        isShow: {
            required: true,
            type: Boolean
        }
    },
}
</script>
