import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "page-title-block d-flex align-items-center flex-wrap justify-content-between page-title-container"
}
const _hoisted_2 = { class: "page-title mb-0" }
const _hoisted_3 = { class: "mb-0" }
const _hoisted_4 = { class: "page-title-desc" }
const _hoisted_5 = { class: "mb-0" }
const _hoisted_6 = {
  key: 1,
  class: "page-title-block result-listing-title inner expat-compare-title-header"
}
const _hoisted_7 = { class: "row align-items-center" }
const _hoisted_8 = { class: "col-lg-6" }
const _hoisted_9 = { class: "page-title mb-0" }
const _hoisted_10 = { class: "mb-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($props.isShow)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h2", _hoisted_3, _toDisplayString($props.title), 1)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h5", _hoisted_5, _toDisplayString(_ctx.$t('will_take')), 1)
        ])
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("h2", _hoisted_10, _toDisplayString($props.title), 1)
            ])
          ])
        ])
      ]))
}