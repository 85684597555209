<template>
  <h-header />
  <router-view />
  <h-footer />
</template>

<script lang="ts">
import { defineComponent, onMounted, getCurrentInstance } from "vue";
import Language from "@/core/services/Language";
import PersonalDetailsService from '@/core/services/PersonalDetailsService';
import router from "./router";

export default defineComponent({
  setup() {
    const currentInstance = getCurrentInstance();
    const dates = currentInstance?.appContext.config.globalProperties.dates;
    onMounted(async () => {
      const routeLanguage =
        router.currentRoute.value.query && router.currentRoute.value.query.lang
          ? router.currentRoute.value.query.lang.toString()
          : "";
        const language = window.localStorage.getItem("language");
      if (routeLanguage) {
        Language.setLanguage(routeLanguage);
      } else {
        if (!language) {
          Language.setLanguage("en");
        }
      }
      const routeUuid =
        router.currentRoute.value.query && router.currentRoute.value.query.uuid
          ? router.currentRoute.value.query.uuid.toString()
          : "";
      const uuid = window.localStorage.getItem("uuid");
      if (routeUuid) {
          window.localStorage.setItem('uuid', routeUuid);
      } else {
        if (!uuid) {
          window.localStorage.setItem('uuid', '');
        }
      }
    });
  },
});
</script>
