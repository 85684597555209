<template>
  <div class="col-md-4 add-ons-block">
    <div class="listing-title">
      <h6 class="mb-0 addons-title">{{ $t("extra_addon") }}</h6>
    </div>
    <div class="extra-addons align-items-center">
      <template v-for="(addonList, addonKey) in addons" :key="addonKey">
        <div class="d-flex">
          <div class="addons-checkbox">
            <input class="styled-checkbox" :id="'addon_' + addonKey" type="checkbox" :checked="addonList.selected"
              v-model="addonList.selected" @change="$emit('toggleAddon', addonList)" />
            <label :for="'addon_' + addonKey"> </label>
          </div>
          <div class="addons-text">
            <div>
              {{ addonList.addon }}
              <strong v-if="addonList.value" dir="ltr">
                {{ addonList.value }}
              </strong>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from "vue";

  export default defineComponent({
    props: {
      addons: {
        type: Object,
        required: true,
      },
    },
  });
</script>