import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "vechile-detail-section"
}
const _hoisted_2 = {
  key: 0,
  class: "container"
}
const _hoisted_3 = { class: "vechile-detail-container" }
const _hoisted_4 = { class: "vechile-detail_row quote-title-block" }
const _hoisted_5 = { class: "row justify-content-between" }
const _hoisted_6 = { class: "form-left-column w-100 pt-10" }
const _hoisted_7 = { class: "vechile-list-container main-container" }
const _hoisted_8 = { class: "vechile-list_row" }
const _hoisted_9 = { class: "result-listing" }
const _hoisted_10 = { class: "result-listing-full-block" }
const _hoisted_11 = {
  key: 1,
  class: "container",
  style: {"min-height":"50vh"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_h_header_mobile = _resolveComponent("h-header-mobile")!
  const _component_h_title = _resolveComponent("h-title")!
  const _component_quote_card = _resolveComponent("quote-card")!
  const _component_no_quote_found = _resolveComponent("no-quote-found")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_h_header_mobile),
    (_ctx.expatPlans && _ctx.expatPlans.length > 0)
      ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
          (_ctx.expatPlans[0].plan_name)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("div", _hoisted_5, [
                      _createElementVNode("div", _hoisted_6, [
                        _createVNode(_component_h_title, {
                          title: _ctx.$t('compare_quotes'),
                          isShow: false
                        }, null, 8, ["title"])
                      ])
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("div", _hoisted_9, [
                      _createElementVNode("div", _hoisted_10, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.expatPlans, (quote, index) => {
                          return (_openBlock(), _createBlock(_component_quote_card, {
                            quote: quote,
                            index: index
                          }, null, 8, ["quote", "index"]))
                        }), 256))
                      ])
                    ])
                  ])
                ])
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_11))
        ]))
      : _createCommentVNode("", true),
    (_ctx.expatPlans && _ctx.expatPlans.length <= 0)
      ? (_openBlock(), _createBlock(_component_no_quote_found, {
          key: 1,
          homeUrl: _ctx.websiteHomePage
        }, null, 8, ["homeUrl"]))
      : _createCommentVNode("", true)
  ], 64))
}