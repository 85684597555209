<template>
  <div
    class="wizard-navigation health-wizard d-flex flex-wrap justify-content-center d-none d-lg-flex d-xl-flex"
  >
    <div
      class="wizard-navigation_item position-relative"
      :class="{active: $route.name === 'PersonalDetails'}"
      style="cursor: pointer"
      @click="redirect('PersonalDetails',$route.name)"
    >
      <div class="wizard-navigation_item--number">1</div>
      <div class="wizard-navigation_item--title">
        <p>
          {{ $t("personal") }} <br />
          {{ $t("details") }}
        </p>
      </div>
    </div>
    <div
      class="wizard-navigation_item position-relative"
      :class="{active: $route.name === 'CompareQuotes'}"
      style="cursor: pointer"
      @click="redirect('CompareQuotes',$route.name)"
    >
      <div class="wizard-navigation_item--number">2</div>
      <div class="wizard-navigation_item--title">
        <p>
          {{ $t("compare") }} <br />
          {{ $t("quotes") }}
        </p>
      </div>
    </div>
    <div
      class="wizard-navigation_item position-relative"
      :class="{active: $route.name === 'ThankYou'}"
      style="cursor: pointer"
    >
      <div class="wizard-navigation_item--number">
        <span class="icon-Tickmark"></span>
      </div>
      <div class="wizard-navigation_item--title">
        <p>                    
          {{ $t("text_your") }} <br />
          {{ $t("text_cover") }}
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: 'DefNav',
  setup() {


          const redirect = async(val, currentRoute='') => {

            if(currentRoute == 'PersonalDetails' && val == 'CompareQuotes'){
              return false;
            }

            let urlParams = {}
              if(window.localStorage.getItem('uuid')){
                urlParams['uuid'] = window.localStorage.getItem('uuid');
              }
              if(window.localStorage.getItem('leadId')){
                urlParams['leadId'] = window.localStorage.getItem('leadId');
              }
              if(window.localStorage.getItem('source')){
                urlParams['source'] = window.localStorage.getItem('source');
              }

              const currentParams = new URLSearchParams(window.location.search);
              for (const [key, value] of currentParams.entries()) {
                if (!(key in urlParams) && !(value)) {
                  urlParams[key] = value;
                }
              }
              
              
              const queryParams = new URLSearchParams(urlParams).toString();

              let url = '/'+process.env.VUE_APP_FOLDER+'/';

              switch(val) {
                  case 'PersonalDetails':
                      url = url+'personal-details' 
                      break
                  case 'CompareQuotes':
                      url = url+'compare-quotes' 
                      break
                  default:
                      url = url+'personal-details'
                      break;
              }
              url = url+'?'+queryParams;
              return window.location.href = url;
            }
        return {
          redirect
        }
      },
});
</script>
