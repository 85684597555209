import moment from "moment/moment";
import { reactive } from "vue";

const screen = reactive({
  width: window.screen.width
})

const thankYouPopup = reactive({
  open : false
})

const dates = reactive({
  min: moment().subtract(18,'y').format('YYYY-MM-DD'),
  today: moment().format('YYYY-MM-DD'),
  expected_date: moment().add(6, 'M').format('YYYY-MM-DD')
})

export {  dates, screen, thankYouPopup };