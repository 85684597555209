
<template>
    <section class="vechile-list page-content">
        <div class="container">
            <div class="vechile-list-container main-container">
                <div class="vechile-list_row">

                    <div class="result-listing-full-block">
                        <div class="text-center no-result-block">
                            <h2>{{ $t('thank_you') }}</h2>
                            <p>
                                {{ $t('callback_thank_you_msg') }}
                            </p>
                            <hr>
                            <p>
                                <span v-html="$t('no_quotes_manual_quote_title')"></span>
                                <a href="https://api.whatsapp.com/send?phone=96171396654" target="_blank">
                                    <img height="25" width="25" src="@/assets/images/icons/whatsapp.svg"></a>
                                <br />
                                <a :href="`${websiteUrl}/${language}`" class="a-link" style="color: #0d6efd">{{ $t('go_back_home') }}</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script lang="ts">
import { defineComponent, computed } from 'vue'
import { store } from '@/store';

export default defineComponent({
    setup() {
        const websiteUrl = computed(() => {
            return process.env.VUE_APP_WEBSITE_URL
        })

        const host = computed(() => {
            return (process.env.VUE_APP_FOLDER !== '') ? `/${process.env.VUE_APP_FOLDER}/personal-details` : `/personal-details`;
        });

        const language = computed(() => {
            return window.localStorage.getItem('language');
        })

        return {
            host,
            language,
            websiteUrl
        }
    },
})
</script>
<style>
.page-content .main-container {
    padding: 3rem 0 5rem;
}

.no-result-block {
    padding: 30px 0;
}

.result-listing-full-block .no-result-block h2 {
    font-family: objective;
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0;
    color: #222261;
}

.result-listing-full-block .no-result-block .list {
    max-width: 880px;
    margin: 40px auto;
    text-align: left;
}

.result-listing-full-block .no-result-block .list li {
    font-size: 16px;
    line-height: 25px;
    color: #5b606b;
}
</style>