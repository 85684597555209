import { store } from "@/store";
import { App } from "vue";
import { QuoteActionTypes } from "@/core/enum/Quotes/Actions";
import {ExpatPlans} from  '@/core/types';

class Quotes{

    public static vueInstance: App;

    public static init(app: App<Element>) {
        Quotes.vueInstance = app;
    }

    public static async getQuotes(payload:any) {
        return await store
        .dispatch(QuoteActionTypes.GET_QUOTES, payload)
    }

    public static async buyNow(payload:any) {
        return await store
        .dispatch(QuoteActionTypes.PROCEED_BUY,payload)
    }

    public static async getVat() {
        return await store.dispatch(QuoteActionTypes.GET_VAT)
    }

}

export default Quotes;