import { ActionTree, ActionContext } from 'vuex';
import ApiService from '@/core/services/ApiService';

// eslint-disable-next-line import/no-cycle
import { RootState } from '@/store';

import { State } from './state';
import { Mutations } from './mutations';
import { PersonalMutationTypes } from '@/core/enum/Personals/Mutations';
import { PersonalActionTypes } from '@/core/enum/Personals/Actions';
import { Links } from '@/core/enum/Links';
import axios from 'axios';
import { ExpatHealthLeadDetails } from '@/core/types';
import router from '@/router';

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1],
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions {
  [PersonalActionTypes.POST_PERSONAL_DETAILS](
    { commit }: AugmentedActionContext,
    payload: ExpatHealthLeadDetails
  ): any;
  [PersonalActionTypes.GET_CUSTOMER](
    { commit }: AugmentedActionContext,
    payload: ExpatHealthLeadDetails
  ): any;
  [PersonalActionTypes.GET_LEAD_DETAILS](
    { commit }: AugmentedActionContext,
    payload: ExpatHealthLeadDetails
  ): any;

}

export const actions: ActionTree<State, RootState> & Actions = {
  async [PersonalActionTypes.POST_PERSONAL_DETAILS]({ commit }, payload) {
    commit(PersonalMutationTypes.SET_CUSTOMER_LOADING, true);
    try {
      const data = await axios.post(Links.POST_PERSONAL_DETAILS, payload);
      const response = data.data.data;
      const personal_details = response.customer;
      
      window.localStorage.setItem('lead_id', response.lead_id)
      if(response.uuid  != window.localStorage.getItem('uuid') && (response.uuid !== 'undefined')) {
        window.localStorage.setItem('uuid', response.uuid)
      } 
      commit(PersonalMutationTypes.SET_CUSTOMER_RESULT, personal_details);
      commit(PersonalMutationTypes.SET_CUSTOMER_LOADING, false);      
      window.localStorage.setItem('personal_details', JSON.stringify(personal_details));
      // window.localStorage.setItem('personal_detail_submitted', JSON.stringify(payload));
      // const route = router.resolve({ name: 'ThankYou' });
      const route = router.resolve({ name: 'CompareQuotes' });
      window.location.href = route.href;
    }
    catch (err) {
      return err;
    }
  },
  async [PersonalActionTypes.GET_CUSTOMER]({ commit }, payload) {
    try {
      const data = await axios.post(Links.GET_CUSTOMER, payload);
      const personal_details = data.data.data.customer;
      
      const filtereCustomer = customer(personal_details, payload);
      commit(PersonalMutationTypes.SET_CUSTOMER, filtereCustomer);
      
      commit(PersonalMutationTypes.SET_CUSTOMER_RESULT, personal_details);
      commit(PersonalMutationTypes.SET_CUSTOMER_LOADING, false);      
      window.localStorage.setItem('personal_details', JSON.stringify(personal_details));
      return true;
    }
    catch (err) {
      return true;
      return err;
    }
  },

  async [PersonalActionTypes.GET_LEAD_DETAILS]({ commit }, payload: any) {
    try {

      const DETAILS_URL        = (Links.GET_LEAD_DETAILS).replace('LEADID', payload.lead_id);
      const response           = await axios.get(DETAILS_URL);
      const leadDetails        = response.data.data;
      const customerData       = response.data.data.customer;
      const filterCustomerData = customer(leadDetails, payload);
      commit(PersonalMutationTypes.SET_CUSTOMER, filterCustomerData);
      commit(PersonalMutationTypes.SET_CUSTOMER_RESULT, customerData);
      commit(PersonalMutationTypes.SET_CUSTOMER_LOADING, false);      
      window.localStorage.setItem('personal_details', JSON.stringify(customerData));
      return true;
    }
    catch (err) {
      return true;
      return err;
    }
  },

};


function customer(data, payload) {
  const customerData  = data.customer
  const leadData      = data.lead_information;
  const uuid     = data.uuid;
  const expat = {
    id: customerData.id,
    lang: window.localStorage.getItem('language') ?? leadData.language,
    customer_name: customerData.customer_name,
    customer_email: customerData.customer_email ?? null,
    customer_phone: customerData.customer_phone,
    expat_name: leadData.lead_name,
    expat_dob: leadData.lead_dob,
    expat_gender: leadData.lead_gender,
    phone_country_code: customerData.phone_country_code ?? '+961',
    insurance_type: customerData.insurance_type ?? 7,
    customer_source: customerData.customer_source ?? null,
    utm_source: customerData.utm_source ?? null,
    utm_medium: customerData.utm_medium ?? null,
    utm_campaign: customerData.utm_campaign ?? null,
    utm_content: customerData.utm_content ?? null,
    uuid: uuid,
    lead_id:leadData.id ?? null
  }
  return expat;
}