import { GetterTree } from 'vuex';

// eslint-disable-next-line import/no-cycle
import { RootState } from '@/store';

import { ExpatPlans } from '@/core/types/Quotes';

import { State } from './state';

export type Getters = {
  getExpatPlans(state: State): Array<ExpatPlans> | null;
}

export const getters: GetterTree<State, RootState> & Getters = {
  getExpatPlans: (state) =>  {
    const quotes = state.expatPlans
    console.log(quotes);
    return quotes
  },
};
