<template>
  <div
    class="result-listing__item lowest-price position-relative expat-insurance-item"
  >
    <div class="result-listing__item__block overflow-hidden">
      <div class="result-listing__item__block-top">
        <div class="row g-0">
          <div class="col-lg-10 col-md-12 left-col">
            <div class="result-listing__item__block_left h-100">
              <div class="result-listing__item__block_top">
                <div
                  class="result-listing__item__block_top_row d-flex flex-wrap align-items-center"
                >
                  <div class="partner-logo">
                    <img :src="`${quote.provider_image}`" class="img-fluid" />
                  </div>
                  <div class="partner-content">
                    <div class="partner-content_title">
                      <h2 class="mb-0">{{ quote.plan_name }}</h2>
                    </div>
                    <div class="partner-content_bottom d-flex">
                      <div class="partner-content_bottom_item">
                        <p class="line-height-15">
                            {{ quote.network_type }} 
                            <span class="network-type-condition" v-if="quote.network_type_condition" >
                                  {{ quote.network_type_condition }}
                            </span>
                        </p>
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="result-listing__item__block_medium">
                <div class="row gx-5">
                  <div class="col-md-8 col-sm-12">
                    <div class="listing-ul">
                      <ul class="row">
                        <template
                          v-for="(benefit, bnIndex) in quote.benefits"
                          :key="bnIndex"
                        >
                          <li :class="benefit.icon + ` col-md-6 col-sm-12`">
                            {{ benefit.benefit }}
                            <span class="mw-100">{{ benefit.value }}</span>
                          </li>
                        </template>
                      </ul>
                    </div>
                  </div>

                  <quote-addon
                    v-if="quote.addons && quote.addons.length > 0"
                    :addons="quote.addons"
                    @toggle-addon="handleToggleAddon"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-2 col-md-12 right-col">
            <div class="right-buy-now text-center">
              <div class="right-buy-now-top">
                <div class="premium-title">
                  <p>{{ $t("total_premium") }}</p>
                </div>
                <div class="price-title">
                  <h4 class="mb-0" style="direction: ltr">
                    $ {{ $filters.currency(quote.premium) || 0 }}
                  </h4>
                </div>
                <div class="buy-now-button">
                  <el-button
                    :disabled="quote.premium <= 0"
                    @click.prevent="BuyNow(quote)"
                    class="btn buy-now"
                    >{{ $t("buy_now") }}</el-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, defineProps } from "vue";
import QuoteAddon from "@/components/quotes/components/QuoteAddon.vue";
import { ElLoading } from "element-plus";
import router from "@/router";
import axios from "axios";
import { Links } from "@/core/enum/Links";

export default defineComponent({
  props: {
    quote: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  components: {
    QuoteAddon,
  },
  setup(props) {
    const BuyNow = async (quote) => {
      const loading = ElLoading.service({
        lock: true,
        text: "Loading",
        background: "rgba(0, 0, 0, 0.9)",
      });

      const lead_id: any = window.localStorage.getItem("lead_id");
      const selectedAddons = props.quote.selected_addons ?? [];
      const payload = {
        lead_id: lead_id,
        premium_id: quote.premium_id,
        selected_addon: selectedAddons,
      };

      loading.close();
      const data = await axios.post(Links.PROCEED_BUY, payload);
      const route = router.resolve({ name: "ThankYou" });
      window.location.href = route.href;
    };

    const handleToggleAddon = (addon) => {
      const isSelected = addon.selected ?? false;
      const addonId = addon.addon_id;
      let selectedAddons = props.quote.selected_addons ?? [];
      if (isSelected) {
        props.quote.premium += addon.amount;
        addon.prev_price = addon.amount;
        selectedAddons.push(addonId);
      } else {
        props.quote.premium -= addon.prev_price;
        const index = selectedAddons.indexOf(addonId);
        if (index > -1) {
          selectedAddons.splice(index, 1);
        }
      }
      props.quote.selected_addons = selectedAddons;
    };

    return {
      BuyNow,
      handleToggleAddon,
    };
  },
});
</script>
