import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "col-md-4 add-ons-block" }
const _hoisted_2 = { class: "listing-title" }
const _hoisted_3 = { class: "mb-0 addons-title" }
const _hoisted_4 = { class: "extra-addons align-items-center" }
const _hoisted_5 = { class: "addons-checkbox" }
const _hoisted_6 = ["id", "checked", "onUpdate:modelValue", "onChange"]
const _hoisted_7 = ["for"]
const _hoisted_8 = { class: "addons-text" }
const _hoisted_9 = {
  key: 0,
  dir: "ltr"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h6", _hoisted_3, _toDisplayString(_ctx.$t("extra_addon")), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.addons, (addonList, addonKey) => {
        return (_openBlock(), _createElementBlock("div", {
          key: addonKey,
          class: "d-flex"
        }, [
          _createElementVNode("div", _hoisted_5, [
            _withDirectives(_createElementVNode("input", {
              class: "styled-checkbox",
              id: 'addon_' + addonKey,
              type: "checkbox",
              checked: addonList.selected,
              "onUpdate:modelValue": ($event: any) => ((addonList.selected) = $event),
              onChange: ($event: any) => (_ctx.$emit('toggleAddon', addonList))
            }, null, 40, _hoisted_6), [
              [_vModelCheckbox, addonList.selected]
            ]),
            _createElementVNode("label", {
              for: 'addon_' + addonKey
            }, null, 8, _hoisted_7)
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", null, [
              _createTextVNode(_toDisplayString(addonList.addon) + " ", 1),
              (addonList.value)
                ? (_openBlock(), _createElementBlock("strong", _hoisted_9, _toDisplayString(addonList.value), 1))
                : _createCommentVNode("", true)
            ])
          ])
        ]))
      }), 128))
    ])
  ]))
}