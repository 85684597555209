import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import App from '../App.vue'
import PersonalDetails from '../views/PersonalDetails.vue';
import QuotesDetails from '../views/QuotesDetails.vue';
import ThankYou from '../views/ThankYou.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    redirect: '/personal-details',
    component: App,
    children: [
      {
        path: '/personal-details',
        name: 'PersonalDetails',
        component: PersonalDetails
      },
      {
        path: '/compare-quotes',
        name: 'CompareQuotes',
        component: QuotesDetails
      },
      {
        path: '/thank-you',
        name: 'ThankYou',
        component: ThankYou
      },
    ]
  },
  
]

const router = createRouter({
  history: createWebHistory(process.env.VUE_APP_FOLDER),
  routes
})

export default router
