import { MutationTree } from 'vuex';

import { ExpatPlans } from '@/core/types/Quotes';

import { State } from './state';
import { QuoteMutationTypes } from '@/core/enum/Quotes/Mutations';


export type Mutations<S = State> = {
  [QuoteMutationTypes.SET_QUOTES](state: S, payload: Array<ExpatPlans>): void;
}

export const mutations: MutationTree<State> & Mutations = {
  [QuoteMutationTypes.SET_QUOTES](state: State, payload: Array<ExpatPlans>) {
    state.expatPlans = payload;
  },
};

