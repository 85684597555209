import { ExpatHealthLeadDetails, CustomerAndLeadId } from '@/core/types';

export type State = {
  customer: ExpatHealthLeadDetails;
  result: CustomerAndLeadId;
  loading: Boolean;
}

export const state: State = {
  customer: {
    lang: window.localStorage.getItem('language'),
    expat_name: '',
    customer_name: '',
    customer_email: '',
    customer_phone: '',
    phone_country_code: '+961',
    expat_dob: '',
    expat_gender: '',
    customer_source: '',
    utm_source: '',
    utm_medium: '',
    utm_campaign: '',
    utm_content: '',
    uuid: '',
    lead_id: '',
  } as ExpatHealthLeadDetails,

  result: {} as CustomerAndLeadId,
  loading: false,
};