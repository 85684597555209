<template>
  <el-form
    ref="expatFormPersonalDetails"
    :model="customer"
    status-icon
    :rules="rules"
    class="expat-form-personal-details"
  >
    <div class="vechile-details-list">
      
      <h-input
        isRequired
        :label="$t('expat_full_name')"
        isType="text"
        formProp="expat_name"
        v-model="customer.expat_name"
        :placeHolder="$t('expat_full_name')"
        maxLength="62"
        :onkeydown="language === 'en' ? `return /[a-zA-Z\\s'\\- ]/i.test(event.key)` : ''"
      />

      <h-date-picker-v2
        isRequired 
        v-model:model-value="customer.expat_dob" 
        form-prop="expat_dob"
        :label="$t('expat_dob')" ref="dob" :max="dateConst.min"
      />

      <h-gender
        isRequired
        :label="$t('expat_gender')"
        isType="select"
        formProp="expat_gender"
        v-model="customer.expat_gender"
      />


      <h-input
        isRequired
        :label="$t('full_name')"
        isType="text"
        formProp="customer_name"
        v-model="customer.customer_name"
        :placeHolder="$t('full_name_placeholder')"
        maxLength="62"
        :onkeydown="language === 'en' ? `return /[a-zA-Z\\s'\\- ]/i.test(event.key)` : ''"
      />

      <h-input
        isRequired
        :label="$t('phone_number')"
        isType="phone"
        formProp="customer_phone"
        :maxLength="customer.phone_country_code == '+961' ? '8' : '10'"
        :placeHolder="$t('phone_number_placeholder')"
        v-model:modelValue="customer.customer_phone"
        v-model:countryCode="customer.phone_country_code"
      />

      <h-input
        isRequired
        :label="$t('email_address')"
        isType="text"
        formProp="customer_email"
        :placeHolder="$t('email_address_placeholder')"
        v-model="customer.customer_email"
        maxLength="62"
      />



      <h-proceed @handleClick="personalDetails.click(expatFormPersonalDetails)" :fullscreenLoading="loading" />
    </div>
  </el-form>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, reactive, ref } from "vue";
import { expatFormPersonalDetails } from "@/store/stateless";
import { emirates } from "@/core/utils";
import { store } from "@/store";
import Validations from "@/core/validations";
import router from '@/router';
import PersonalDetailsService from "@/core/services/PersonalDetailsService";
import moment from 'moment'
import { dates as dateConst} from "@/store/stateless"

export default defineComponent({
  setup(_) {
    const customer = computed(() => {
      return store.state.personals.customer;
    });

    const loading = computed(() => {
      return store.state.personals.loading
    })

    const language = computed(() => {
      return window.localStorage.getItem('language');
    })

    const getRouterParameters = (property,defaultValue='') => (router.currentRoute.value.query[property])?.toString() || defaultValue;

    onMounted(async() => {

      customer.value.uuid         = getRouterParameters('uuid',(window.localStorage.getItem("uuid") ?? ''));
      customer.value.lead_id      = getRouterParameters('lead_id',(window.localStorage.getItem("lead_id") ?? ''));
      customer.value.utm_source   = getRouterParameters('utm_source','website');
      customer.value.utm_medium   = getRouterParameters('utm_medium','website');
      customer.value.utm_campaign = getRouterParameters('utm_campaign','nc');
      customer.value.utm_content  = getRouterParameters('utm_content','website');

      if(customer.value.lead_id) {
        const result = await PersonalDetailsService.getLeadDetails(customer.value)
      }  
    })
    
    const rules = reactive({
      expat_name: [{ validator: Validations.expatName, trigger: ["blur", "change"] }],
      customer_name: [{ validator: Validations.names, trigger: ["blur", "change"] }],
      customer_phone: [{ validator: Validations.phone_number, trigger: ["blur", "change"] }],
      customer_email: [{ validator: Validations.email, trigger: ["blur", "change"] }],
      expat_gender: [{ validator: Validations.names, trigger: ["blur", "change"] }],
      expat_dob: [{ validator: Validations.dob, trigger: ["blur", "change"] }],
    });

    return {
      loading,
      expatFormPersonalDetails,
      emirates,
      customer,
      language,
      rules,
      dateConst
    };
  },
});
</script>